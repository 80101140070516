

#gpio {
  position: relative;
  width: 100%;
  max-width: 456px;
  min-width: 420px;
  min-height: 493px;
  background: #5f8645;
  font-size: 20px;
  font-weight: 500;
}
#gpio *, *::before, *::after {
  box-sizing: content-box
}

#gpio:before {
  content: '';
  display: block;
  width: 58px;
  position: absolute;
  left: calc(50% - 29px);
  height: 493px;
  background: #073642;
  top: 0
}

#gpio ul {
  position: relative;
  top: 7px;
  list-style: none;
  display: block;
  width: 50%;
  float: left;
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  
}

#gpio a {
  display: block;
  position: relative;
  font-size: .84em;
  line-height: 22px;
  height: 22px;
  margin-bottom: 2px;
  color: #e9e5d2;
  text-decoration: none;
}

#gpio .phys {
  color: #073642;
  font-size: .8em;
  opacity: .8;
  position: absolute;
  left: 32px;
  text-indent: 0
}

#gpio .pin {
  display: block;
  border: 1px solid transparent;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background: #002b36;
  position: absolute;
  right: 4px;
  top: 2px
}

#gpio .pin:after {
  content: '';
  display: block;
  border-radius: 100%;
  background: #fdf6e3;
  position: absolute;
  left: 5px;
  top: 5px;
  width: 6px;
  height: 6px
}

#gpio .top li {
  text-indent: 56px
}

#gpio .top a {
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px
}

#gpio .top .overlay-ground .phys {
  padding-left: 31px;
  left: 0
}

#gpio .top .pin {
  left: 4px;
  top: 2px
}

#gpio .bottom a {
  text-indent: 10px;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px
}

#gpio .bottom .overlay-ground .phys {
  padding-right: 32px;
  right: 0
}

#gpio .bottom .phys {
  text-align: right;
  left: auto;
  right: 32px
}

#gpio .gnd a {
  color: rgba(233, 229, 210, .5)
}

#gpio:hover {
  color: rgba(6, 53, 65, .5)
}

#gpio a:hover,
#gpio .active a {
  background: #f5f3ed;
  color: #063541
}

#gpio li a small {
  font-size: .7em
}

#gpio .overlay-pin a {
  background: #ebe6d3;
  color: #063541
}

#gpio .overlay-pin a:hover {
  background: #f5f3ed;
  color: #063541
}

#gpio .overlay-pin.gnd a {
  color: rgba(6, 53, 65, .5)
}

#gpio .overlay-power .phys {
  color: #fff;
  opacity: 1
}

#gpio .overlay-power a {
  background: #073642;
  color: #fff
}

#gpio .overlay-power a:hover {
  background: #268bd2
}

#gpio .overlay-ground .phys {
  background: #073642;
  color: #fff;
  opacity: 1;
  position: absolute;
  top: 0;
  width: 20px;
  height: 22px;
  border-radius: 11px;
  text-indent: 0;
  line-height: 22px
}

#gpio .overlay-ground a:hover .phys {
  background: #268bd2
}

#gpio .overlay-ground span.pin {
  background: #073642
}

#gpio ul li.hover-pin a,
#gpio .bottom li.hover-pin a {
  color: #fff;
  background: rgba(200, 0, 0, .6)
}

#gpio ul li.hover-pin a .phys,
#gpio .bottom li.hover-pin a .phys {
  color: #fff
}

#gpio .pin1 a:hover,
#gpio .pin1.active a,
#gpio .pin1 .pin {
  border-radius: 0
}

#gpio .pow3v3 .pin {
  background: #b58900
}

#gpio .pow5v .pin {
  background: #dc322f
}

#gpio .gpio .pin {
  background: #859900
}

#gpio .i2c .pin {
  background: #268bd2
}

#gpio .spi .pin {
  background: #d33682
}

#gpio .pcm .pin {
  background: #2aa198
}

#gpio .uart .pin {
  background: #6c71c4
}